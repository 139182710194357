import { ErrorHandler, Injectable} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ErrorsHandler implements ErrorHandler {
  constructor(
    // private store: Store<fromStore.GlobalAppState>
  ) {
  }

  handleError(error: Error | HttpErrorResponse) {
     if (error instanceof HttpErrorResponse) {
        // Server or connection error happened
        if (!navigator.onLine) {
          // Handle offline error
          // console.log("Offline error", error);
        } else {
          // Handle Http Error (error.status === 403, 404...)
          // console.log("Server Error", error);
        }
     } else {
       // Handle Client Error (Angular Error, ReferenceError...)
       // console.log("Client error", error);
     }
  }
}
