import { createSelector  } from '@ngrx/store';

import * as fromRoot from '../reducers';
import * as fromPanda from '../reducers/panda.reducer';

export const getRootPandaUserState = createSelector((state: fromRoot.GlobalAppState) => state.panda_user);
export const getCurrentPandaUser = createSelector(getRootPandaUserState, fromPanda.getPandaUser);
export const getPandaUserLoaded = createSelector(getRootPandaUserState, fromPanda.getPandaUserLoaded);
export const getPandaUserLoading = createSelector(getRootPandaUserState, fromPanda.getPandaUserLoading);
export const getPandaUserAuthenticated = createSelector(getRootPandaUserState, fromPanda.getPandaUserAuthenticated);
export const getPandaUserAuthenticating = createSelector(getRootPandaUserState, fromPanda.getPandaUserAuthenticating);
export const getPandaRedirectUrl = createSelector(getRootPandaUserState, fromPanda.getPandaRedirectUrl);
