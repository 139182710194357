export const environment = {
  production: false,
  hmr: false,
  cookieAcceptName: 'drivecare-cookie-consent-staging',
  recaptchaKey: '6Ld8A58UAAAAALvhE5YUTsRfS0cZZu27gRLZO0D0',
  stripeKey: 'pk_test_A4wv3qmeDF0oLRDQ8fmJVKz9',
  api: {
    baseUrl: 'https://staging.drivecare.io/api/v2',
    apiKey: 'SLzSk4wMVhwP7s55l4GoZ05JrOBn5o21',
    tokenCookieName: 'drivecare-access-token-staging',
    tokenExpiryName: 'drivecare-access-token-expires-staging',
    userStorageName: 'drivecare-user-staging',
    pandaUserStorageName: 'drivecare-panda-user-staging',
    pandaTokenCookieName: 'drivecare-panda-access-token-staging',
    pandaTokenExpiryName: 'drivecare-panda-access-token-expires-staging',
    distributorClientStorageName: 'drivecare-selected-distributor-client-staging'
  }
};
