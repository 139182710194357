import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-terms-notice',
  templateUrl: './terms-notice.component.html',
  styleUrls: ['./terms-notice.component.scss']
})
export class TermsNoticeComponent implements OnInit {
  @Input() clickText: string = 'Log In';

  constructor() { }

  ngOnInit() {
  }

}
