import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ghost',
  templateUrl: './ghost.component.html',
  styleUrls: ['./ghost.component.scss']
})
export class GhostComponent implements OnInit {
  @Input() header: boolean = false;
  @Input() width: string = '100%';
  @Input() numParagraphs: number = 3;
  @Input() numLinesPerParagraph: number = 5;
  ghostElements: any = [];

  constructor() { }

  ngOnInit() {
    if(!this.header) {
      this.buildParagraphs(this.numParagraphs, this.numLinesPerParagraph);
    }
  }

  buildParagraphs(numParagraphs: number, numLines: number) {
    for(var pI = 0; pI < numParagraphs; pI++) {
      var paragraph = [];

      for(var lI = 0; lI < numLines; lI++) {
        var width = (Math.floor(Math.random() * (100 - 80) ) + 80) + "%";
        var header = false;

        if(pI == 0 && lI == 0) {
          header = true;
          width = '50%';
        }

        var line = {
          width: width,
          header: header
        };

        paragraph.push(line);
      }

      this.ghostElements.push(paragraph);
    }
  }
}
