import * as fromUsers from '../actions/user.actions';
import { User } from '../../core/models/user';
import * as moment from 'moment';

import { environment } from '../../../environments/environment';

export interface UserState {
  data: User;
  authenticating: boolean;
  authenticated: boolean;
  authenticateError: string;
  loading: boolean;
  loaded: boolean;
  redirect_url: string;
  saving_account: boolean;
  saving_company: boolean;
  forgot_pass_loading: boolean;
  forgot_pass_loaded: boolean;
  forgot_pass_message: string;
  reset_pass_loading: boolean;
  reset_pass_loaded: boolean;
  reset_pass_message: string;
  register_loading: boolean;
  register_loaded: boolean;
  register_message: string;
  userUpdateSaving: boolean;
  userUpdateSaved: boolean;
}

export const initialState: UserState = {
  data: new User,
  authenticating: false,
  authenticated: false,
  authenticateError: null,
  loading: false,
  loaded: false,
  redirect_url: '/dashboard',
  saving_account: false,
  saving_company: false,
  forgot_pass_loading: false,
  forgot_pass_loaded: false,
  forgot_pass_message: null,
  reset_pass_loading: false,
  reset_pass_loaded: false,
  reset_pass_message: null,
  register_loading: false,
  register_loaded: false,
  register_message: null,
  userUpdateSaving: false,
  userUpdateSaved: false
};

export function userReducer(
  state = initialState,
  action: fromUsers.UserActions
): UserState {
  let localUser = localStorage.getItem(environment.api.userStorageName);

  switch(action.type) {
    case fromUsers.UserActionTypes.Init_App: {
      return {
        ...state
      }
    }

    case fromUsers.UserActionTypes.Log_In_Start: {
      return {
        ...state,
        authenticating: true,
        authenticated: false,
        authenticateError: null
      };
    }

    case fromUsers.UserActionTypes.Log_In_Success: {
      return {
        ...state,
        authenticating: false,
        authenticated: true,
        authenticateError: null
      };
    }

    case fromUsers.UserActionTypes.Log_In_Failed: {
      return {
        ...state,
        authenticating: false,
        authenticated: false,
        authenticateError: action.payload.error.error.message[0]
      };
    }

    case fromUsers.UserActionTypes.Log_Out_Start: {
      return {
        ...state
      }
    }

    case fromUsers.UserActionTypes.Log_Out_Success: {
      return {
        ...state,
        data: null,
        authenticated: false,
        loaded: false
      }
    }

    case fromUsers.UserActionTypes.Log_Out_Fail: {
      return {
        ...state,
        data: null,
        authenticated: false,
        loaded: false
      }
    }

    case fromUsers.UserActionTypes.Load_User_Start: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case fromUsers.UserActionTypes.Load_User_Success: {
      if(localUser === null || localUser !== action.payload.data) {
        localStorage.setItem(environment.api.userStorageName, JSON.stringify(action.payload.data));
      }

      return {
        ...state,
        data: action.payload.data,
        loading: false,
        loaded: true
      };
    }

    case fromUsers.UserActionTypes.Load_User_Fail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case fromUsers.UserActionTypes.Validate_Auth_Token_Start: {
      return {
        ...state
      };
    }

    case fromUsers.UserActionTypes.Validate_Auth_Token_Success: {
      const userObj = JSON.parse(localUser);

      return {
        ...state,
        data: userObj,
        loaded: true,
        authenticated: true
      };
    }

    case fromUsers.UserActionTypes.Validate_Auth_Token_Fail: {
      return {
        ...state
      };
    }

    case fromUsers.UserActionTypes.Redirect_After_Auth: {
      return {
        ...state,
        redirect_url: action.payload
      };
    }

    case fromUsers.UserActionTypes.Patch_User_Start: {

      return {
        ...state,
        userUpdateSaving: true,
        userUpdateSaved: false
      };
    }

    case fromUsers.UserActionTypes.Patch_User_Success: {
      if(localUser === null || localUser !== action.payload.data) {
        localStorage.setItem(environment.api.userStorageName, JSON.stringify(action.payload.data));
      }

      return {
        ...state,
        data: action.payload.data,
        userUpdateSaving: false,
        userUpdateSaved: true
      };
    }

    case fromUsers.UserActionTypes.Patch_User_Fail: {

      return {
        ...state,
        userUpdateSaving: false,
        userUpdateSaved: false
      };
    }

    case fromUsers.UserActionTypes.Forgot_Pass_Start: {
      return {
        ...state,
        forgot_pass_loading: true,
        forgot_pass_loaded: false,
        forgot_pass_message: null
      };
    }

    case fromUsers.UserActionTypes.Forgot_Pass_Success: {
      return {
        ...state,
        forgot_pass_loading: false,
        forgot_pass_loaded: true,
        forgot_pass_message: action.payload[0]
      };
    }

    case fromUsers.UserActionTypes.Forgot_Pass_Fail: {
      return {
        ...state,
        forgot_pass_loading: false,
        forgot_pass_loaded: false,
        forgot_pass_message: action.payload.error.error.message[0]
      };
    }

    case fromUsers.UserActionTypes.Reset_Pass_Start: {
      return {
        ...state,
        reset_pass_loading: true,
        reset_pass_loaded: false,
        reset_pass_message: null
      };
    }

    case fromUsers.UserActionTypes.Reset_Pass_Success: {
      return {
        ...state,
        reset_pass_loading: false,
        reset_pass_loaded: true,
        reset_pass_message: action.payload[0]
      };
    }

    case fromUsers.UserActionTypes.Reset_Pass_Fail: {
      return {
        ...state,
        reset_pass_loading: false,
        reset_pass_loaded: false,
        reset_pass_message: action.payload.error.error.message[0]
      };
    }

    case fromUsers.UserActionTypes.Register_Start: {

      return {
        ...state,
        register_loading: true,
        register_loaded: false
      };
    }

    case fromUsers.UserActionTypes.Register_Success: {

      return {
        ...state,
        register_loading: false,
        register_loaded: true
      };
    }

    case fromUsers.UserActionTypes.Register_Fail: {

      return {
        ...state,
        register_loading: false,
        register_loaded: false,
        register_message: action.payload.error.error.message[0]
      };
    }

  }

  return state;
}

export const getUser = (state: UserState) => state.data;
export const getUserAuthenticating = (state: UserState) => state.authenticating;
export const getUserAuthenticated = (state: UserState) => state.authenticated;
export const getUserLoading = (state: UserState) => state.loading;
export const getUserLoaded = (state: UserState) => state.loaded;
export const getRedirectUrl = (state: UserState) => state.redirect_url;
