import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GhostComponent } from './ghost/ghost.component';
import { LoaderComponent } from './loader/loader.component';
import { TermsNoticeComponent } from './terms-notice/terms-notice.component';
import { NgxStripeModule } from 'ngx-stripe';
import { RouterModule } from '@angular/router';
import { NgxCaptchaModule } from 'ngx-captcha';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { ColorPickerModule } from 'ngx-color-picker';
import { KnobModule } from 'ng2-knob';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { OwlMomentDateTimeModule } from 'ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCCdv6l3MkMncv8xA045AaPz1-s3NjaB7g",
  authDomain: "drivecare-6f897.firebaseapp.com",
  databaseURL: "https://drivecare-6f897.firebaseio.com",
  projectId: "drivecare-6f897",
  storageBucket: "drivecare-6f897.appspot.com",
  messagingSenderId: "463176796783",
  appId: "1:463176796783:web:9a185999ef5a664185db9a"
};

export const MY_MOMENT_FORMATS = {
    parseInput: 'LL',
    fullPickerInput: 'LL',
    datePickerInput: 'LL',
    timePickerInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
};

import { environment } from '../../../environments/environment';

import { PhonePipe } from '../../core/pipes/phone.pipe';

// FONT AWESOME ICONS
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { faFacebook, faLinkedin, faTwitter, faInstagram, faYoutube, faApple, faAppStore, faGooglePlay } from '@fortawesome/free-brands-svg-icons';
import { ShortNumberPipe } from './shortnumber/short-number.pipe';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { DragNDropDirective } from './dragndrop/drag-ndrop.directive';
library.add(fas, far);
library.add(faFacebook, faLinkedin, faTwitter, faInstagram, faYoutube, faAppStore, faApple, faGooglePlay);

@NgModule({
  declarations: [GhostComponent, LoaderComponent, TermsNoticeComponent, ShortNumberPipe, UploadFileComponent, DragNDropDirective, PhonePipe],
  imports: [
    CommonModule,
    RouterModule,
    NgxCaptchaModule,
    ColorPickerModule,
    KnobModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    OwlMomentDateTimeModule,
    NgxStripeModule.forRoot(environment.stripeKey),
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule,
  ],
  exports: [
    GhostComponent,
    UploadFileComponent,
    DragNDropDirective,
    LoaderComponent,
    NgxStripeModule,
    TermsNoticeComponent,
    FontAwesomeModule,
    NgxCaptchaModule,
    Ng2TelInputModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    OwlMomentDateTimeModule,
    PhonePipe,
    ColorPickerModule,
    KnobModule,
    ShortNumberPipe
  ],
  providers: [
    {
      provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS
    },
  ]
})
export class SharedModule { }
