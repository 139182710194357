export class PandaUser {
  uid: number;
  email: string;
  first_name: string;
  last_name: string;
  time_off_total_count: number;
  time_off_used: number;
  time_off_available: number;
  phone: string;
  image_url: string;
  address: string;
  city: string;
  province: string;
  country: string;
  start_date: number;
  end_date: number;
  created_at: number;
  created_at_timezone: string;
  updated_at: number;
  updated_at_timezone: string;
  deleted_at: number;
  deleted_at_timezone: string;
}
