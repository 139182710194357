import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/do';
import { environment } from '../../../environments/environment';
import { AuthService } from '../services/panda/auth/auth.service';

import { Store } from '@ngrx/store';
import * as fromStore from '../../store';
import { PandaUser } from '../models/user-panda';

@Injectable()
export class PandaApiInterceptor implements HttpInterceptor {

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<{ pandaUser: PandaUser }>,
    private pandaAuthService: AuthService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const bearerToken = this.pandaAuthService.getAuthToken();

    if(request.url.indexOf('/panda') >= 0) {
      const headers = new HttpHeaders({
        'API-KEY': environment.api.apiKey,
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + bearerToken
      });

      const cloneReq = request.clone({headers: headers});

      return next.handle(cloneReq).do((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // do stuff with response
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            if(this.activatedRoute.snapshot['_routerState'].url.indexOf('/admin') >= 0) {
              if(bearerToken && err.url.indexOf('/logout') < 0) {
                console.log("PandaApiInterceptor start log out");

                this.store.dispatch(new fromStore.PandaLogOutStart(bearerToken));
              } else if((bearerToken && err.url.indexOf('/logout') >= 0) || !bearerToken) {
                console.log("PandaApiInterceptor complete log out");

                this.store.dispatch(new fromStore.PandaLogOutSuccess(bearerToken));
              }
            }
          }
        }
      });
    }

    return next.handle(request);
  }
}
