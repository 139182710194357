import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs/observable/of';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import * as fromStore from '../../store';
import * as fromPanda from '../actions/panda.actions';
import { AuthService } from '../../core/services/panda/auth/auth.service';
import { PandaUsersService } from '../../core/services/panda/users/users.service';

@Injectable()
export class PandaEffects {
  constructor(
    private router: Router,
    private actions$: Actions,
    private authService: AuthService,
    private usersService: PandaUsersService,
    private store: Store<fromStore.GlobalAppState>
  ) {
  }

  @Effect({dispatch: true})
  initApp = this.actions$.pipe(
    ofType<fromPanda.PandaUserActions>(fromPanda.PandaUserActionTypes.Init_Panda_App),
    switchMap((action) => [
      (Object.keys(action.payload.data).length !== 0) ? new fromPanda.PandaUserExists() : new fromPanda.PandaValidateAuthTokenStart({redirect: false})
    ])
  )

  @Effect()
  logInStart = this.actions$.pipe(
    ofType(fromPanda.PandaUserActionTypes.Panda_Log_In_Start),
    switchMap((action) => {
      return this.authService.submitAuth(action).pipe(
        map(payload => new fromPanda.PandaLogInSuccess(payload)),
        catchError(error => of(new fromPanda.PandaLogInFailed(error)))
      )
    })
  )

  @Effect()
  logInSuccess = this.actions$.pipe(
    ofType(fromPanda.PandaUserActionTypes.Panda_Log_In_Success),
    map(() => new fromPanda.PandaLoadUserStart())
  )

  @Effect({dispatch: true})
  logOutStart = this.actions$.pipe(
    ofType(fromPanda.PandaUserActionTypes.Panda_Log_Out_Start),
    switchMap((action) => {
      return this.authService.logUserOut(action).pipe(
        map(payload => new fromPanda.PandaLogOutSuccess(payload)),
        catchError(error => of(new fromPanda.PandaLogOutFail(error)))
      )
    })
  )

  @Effect({ dispatch: false })
  logOutSuccess = this.actions$.pipe(
    ofType(fromPanda.PandaUserActionTypes.Panda_Log_Out_Success),
    switchMap(() => {
      return this.authService.removeLocalItems().pipe(
        tap( (action) => {
          this.router.navigate(['/admin/login'], { queryParams: { returnUrl: this.router.url } });
        })
      )
    })
  )

  @Effect()
  loadUserStart = this.actions$.pipe(
    ofType(fromPanda.PandaUserActionTypes.Panda_Load_User_Start),
    switchMap(() => {
      return this.usersService.getPandaUserByToken({
      }).pipe(
        map(response => new fromPanda.PandaLoadUserSuccess(response))
      )
    })
  )

  @Effect({ dispatch: false })
  loadUserSuccess = this.actions$.pipe(
    ofType<fromPanda.PandaUserActions>(fromPanda.PandaUserActionTypes.Panda_Load_User_Success),
    tap( () => { this.router.navigate(['/admin']) })
  )

  @Effect({dispatch: true})
  validateAuthStart = this.actions$.pipe(
    ofType<fromPanda.PandaUserActions>(fromPanda.PandaUserActionTypes.Panda_Validate_Auth_Token_Start),
    switchMap((action) => {
      return this.authService.isAuthTokenValid().pipe(
        map( response => (response) ? new fromPanda.PandaValidateAuthTokenSuccess(action.payload) : new fromPanda.PandaValidateAuthTokenFail())
      )
    })
  )

  @Effect({ dispatch: false })
  validateAuthTokenSuccess = this.actions$.pipe(
    ofType<fromPanda.PandaUserActions>(fromPanda.PandaUserActionTypes.Panda_Validate_Auth_Token_Success),
    tap( (action) => {
      if(action.payload.redirect) {
        this.router.navigate([(action.payload.redirectUrl) ? action.payload.redirectUrl : '/admin'])
      }
    })
  )

  @Effect({ dispatch: false })
  validateAuthTokenFail = this.actions$.pipe(
    ofType<fromPanda.PandaUserActions>(fromPanda.PandaUserActionTypes.Panda_Validate_Auth_Token_Fail)
  )
}
