import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PandaUsersService {
  usersUrl = environment.api.baseUrl + '/panda/users';
  today = moment();

  constructor(
    private http: HttpClient
  ) {}

  getPandaUserByToken(urlParams?: any): Observable<HttpEvent<any>> {
    return this.http.get<any>(this.usersUrl, { params: urlParams })
    .pipe(map(response => {
        return response;
    }));
  }
}
