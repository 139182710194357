import { Action } from '@ngrx/store';

export enum PandaUserActionTypes {
  Init_Panda_App = '[Panda] Init Panda App',
  Panda_User_Exists = '[Panda] User Already Exists',
  Panda_Log_In_Start = '[Panda] Starting Log In',
  Panda_Log_In_Success = '[Panda] Logged In',
  Panda_Log_In_Failed = '[Panda] Login Failed',
  Panda_Log_Out_Start = '[Panda] Start Log Out',
  Panda_Log_Out_Success = '[Panda] Log Out Success',
  Panda_Log_Out_Fail = '[Panda] Log Out Fail',
  Panda_Load_User_Start = '[Panda] Start Loading User',
  Panda_Load_User_Success = '[Panda] Got User Data',
  Panda_Load_User_Fail = '[Panda] Failed to Get User',
  Panda_Validate_Auth_Token_Start = '[Panda] Start Validating Auth Token',
  Panda_Validate_Auth_Token_Success = '[Panda] Successfully Validated Auth Token',
  Panda_Validate_Auth_Token_Fail = '[Panda] Failed to Validate Auth Token',
  Panda_Redirect_After_Auth = '[Panda] Redirect After Authentication'
}

export class InitPandaApp implements Action {
  readonly type = PandaUserActionTypes.Init_Panda_App;
  constructor(public payload: any = null) {}
}

export class PandaUserExists implements Action {
  readonly type = PandaUserActionTypes.Panda_User_Exists;
  constructor(public payload: any = null) {}
}

export class PandaLogInStart implements Action {
  readonly type = PandaUserActionTypes.Panda_Log_In_Start;
  constructor(public payload: any = null) {}
}

export class PandaLogInSuccess implements Action {
  readonly type = PandaUserActionTypes.Panda_Log_In_Success;
  constructor(public payload: any = null) {}
}

export class PandaLogInFailed implements Action {
  readonly type = PandaUserActionTypes.Panda_Log_In_Failed;
  constructor(public payload: any = null) {}
}

export class PandaLogOutStart implements Action {
  readonly type = PandaUserActionTypes.Panda_Log_Out_Start;
  constructor(public payload: any = null) {}
}

export class PandaLogOutSuccess implements Action {
  readonly type = PandaUserActionTypes.Panda_Log_Out_Success;
  constructor(public payload: any = null) {}
}

export class PandaLogOutFail implements Action {
  readonly type = PandaUserActionTypes.Panda_Log_Out_Fail;
  constructor(public payload: any = null) {}
}

export class PandaLoadUserStart implements Action {
  readonly type = PandaUserActionTypes.Panda_Load_User_Start;
  constructor(public payload: any = null) {}
}

export class PandaLoadUserSuccess implements Action {
  readonly type = PandaUserActionTypes.Panda_Load_User_Success;
  constructor(public payload: any = null) {}
}

export class PandaLoadUserFail implements Action {
  readonly type = PandaUserActionTypes.Panda_Load_User_Fail;
  constructor(public payload: any = null) {}
}

export class PandaRedirectAfterAuth implements Action {
  readonly type = PandaUserActionTypes.Panda_Redirect_After_Auth;
  constructor(public payload: any = '/admin') {}
}

export class PandaValidateAuthTokenStart implements Action {
  readonly type = PandaUserActionTypes.Panda_Validate_Auth_Token_Start;
  constructor(public payload: any = null) {}
}

export class PandaValidateAuthTokenSuccess implements Action {
  readonly type = PandaUserActionTypes.Panda_Validate_Auth_Token_Success;
  constructor(public payload: any = null) {}
}

export class PandaValidateAuthTokenFail implements Action {
  readonly type = PandaUserActionTypes.Panda_Validate_Auth_Token_Fail;
  constructor(public payload: any = null) {}
}

export type PandaUserActions =
  | InitPandaApp
  | PandaUserExists
  | PandaLogInStart
  | PandaLogInSuccess
  | PandaLogInFailed
  | PandaLogOutStart
  | PandaLogOutSuccess
  | PandaLogOutFail
  | PandaLoadUserStart
  | PandaLoadUserSuccess
  | PandaLoadUserFail
  | PandaRedirectAfterAuth
  | PandaValidateAuthTokenStart
  | PandaValidateAuthTokenSuccess
  | PandaValidateAuthTokenFail;
