export class User {
  uid: number;
  email: string;
  firstName: string;
  lastName: string;
  phone_country_code: number;
  phone_country_letter_code: string;
  phone: string;
  phone_location_address: string;
  company_name: string;
  company_phone: string;
  company_address: string;
  company_city: string;
  company_postal: string;
  company_province: string;
  company_country: string;
  allow_bt: boolean;
  maxSpeed: number;
  alertEmails: boolean;
  stripe_id: string;
  stripe_customer: any;
  stripe_invoices: any;
  stripe_total_value: number;
  account_type: string;
  subscription_id: string;
  subscription_type: string;
  fee_per_unit: string;
  language: string;
  measurement_type: string;
  only_incoming: boolean;
  allow_passenger_mode: boolean;
  show_lockscreen: string;
  lockscreen_driver_score_threshold: number;
  is_onboarded: boolean;
  num_vehicles: number;
  num_operators: number;
  num_trips: number;
  callsBlocked: number;
  textsBlocked: number;
  location: string;
  location_details: any;
  satisfaction_level: string;
  support_tickets: any;
  unread_alerts: any;
  total_trip_count: number;
  total_kms_driven: number;
  textAlerts: boolean;
  registered: number;
  lastUpdated: number;
  is_distributor: boolean;
  logged_in: number;
  created_at: number;
  created_at_timezone: string;
  updated_at: number;
  updated_at_timezone: string;
  deleted_at: number;
  deleted_at_timezone: string;
}
