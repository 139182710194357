import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loginUrl = environment.api.baseUrl + '/owl/login';
  logOutUrl = environment.api.baseUrl + '/owl/logout';
  forgotPassUrl = environment.api.baseUrl + '/owl/password/email';
  resetPassUrl = environment.api.baseUrl + '/owl/password/reset';
  registerUrl = environment.api.baseUrl + '/owl/register';
  today = moment();
  clearedUserData: BehaviorSubject<boolean>;
  validAuthToken: BehaviorSubject<boolean>;
  loggedOut: BehaviorSubject<boolean>;

  constructor(
    private http: HttpClient
  ) {
    this.clearedUserData = new BehaviorSubject<boolean>(true);
    this.validAuthToken = new BehaviorSubject<boolean>(false);
    this.loggedOut = new BehaviorSubject<boolean>(false);
  }

  submitAuth(data: any): Observable<HttpEvent<any>> {
    return this.http.post<any>(this.loginUrl, data.payload)
    .pipe(map(response => {
        if (response && response.access_token) {
          localStorage.setItem(environment.api.tokenCookieName, response.access_token);
          localStorage.setItem(environment.api.tokenExpiryName, this.today.add(response.expires_in, 'seconds').format());
        }

        return response;
    }));
  }

  forgotPass(data: any): Observable<HttpEvent<any>> {
    return this.http.post<any>(this.forgotPassUrl, data)
    .pipe(map(response => {
        return response;
    }));
  }

  resetPass(data: any): Observable<HttpEvent<any>> {
    return this.http.post<any>(this.resetPassUrl, data)
    .pipe(map(response => {
        return response;
    }));
  }

  registerNewUser(data: any): Observable<HttpEvent<any>> {
    return this.http.post<any>(this.registerUrl, data)
    .pipe(map(response => {
        return response;
    }));
  }

  getAuthToken(): String {
    let authToken = localStorage.getItem(environment.api.tokenCookieName);

    return authToken;
  }

  isAuthTokenValid(): Observable<boolean> {
    let localTokenExpiry = localStorage.getItem(environment.api.tokenExpiryName);
    let localTokenExpiryMoment = moment(localTokenExpiry);
    this.validAuthToken.next(localTokenExpiryMoment.isAfter(this.today));

    return this.validAuthToken.asObservable();
  }

  clearUserData(): Observable<boolean> {
    let localUser = localStorage.getItem(environment.api.userStorageName);

    if(localUser !== null) {
      this.removeLocalItems();
    }

    return this.clearedUserData.asObservable();
  }

  logUserOut(action): Observable<HttpEvent<any>> {
    return this.http.post<any>(this.logOutUrl, {})
      .pipe(map(response => {
        this.removeLocalItems();
        return response;
      }));
  }

  removeLocalItems() : Observable<boolean> {
    localStorage.removeItem(environment.api.userStorageName);
    localStorage.removeItem(environment.api.tokenExpiryName);
    localStorage.removeItem(environment.api.tokenCookieName);
    this.loggedOut.next(true);

    return this.loggedOut.asObservable();
    // this.store.dispatch(new ClearedUserData());
  }
}
