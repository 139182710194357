import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import { AuthService } from '../users/auth/auth.service';

import { environment } from '../../../../environments/environment';

import { Store, select } from '@ngrx/store';
import * as rootStore from '../../../store';

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {
  initialized: boolean = false;
  userState: any;

  constructor(
    private authService: AuthService,
    private globalStore: Store<rootStore.GlobalAppState>,
  ) { }

  /**
   * Initialize mixpanel.
   *
   * @param {string} userToken
   * @memberof MixpanelService
   */
  init(userToken: string): void {
    if(!this.initialized && environment.production) {
      mixpanel.init(userToken);

      this.authService.isAuthTokenValid().subscribe(response => {
        if(response) {
          // Load user state
          this.userState = this.globalStore.select(rootStore.getUserState).subscribe(userState => {
            let user = userState.data;
            mixpanel.identify(userState.data.uid);
            mixpanel.people.set({
              "$name": user.firstName + " " + user.lastName,
              "$first_name": user.firstName,
              "$last_name": user.lastName,
              "$email": user.email,
              "$phone": user.phone_country_code + "" + user.phone
            });
          });
        } else {
          let randomNumber = Math.floor(Math.random() * (+50000000 - +10000000)) + +10000000;
          mixpanel.identify(randomNumber);
        }
      });
      this.initialized = true;
    }
  }

  /**
   * Push new action to mixpanel.
   *
   * @param {string} id Name of the action to track.
   * @param {*} [action={}] Actions object with custom properties.
   * @memberof MixpanelService
   */
  track(id: string, action: any = {}): void {
    if(environment.production) {
      mixpanel.track(id, action);
    }
  }
}
