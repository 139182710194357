import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from './modules/shared/shared.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ApiInterceptor } from './core/interceptors/api.interceptor';
import { PandaApiInterceptor } from './core/interceptors/panda.api.interceptor';
import { ErrorsHandler } from './core/interceptors/errors.handler';
import { FacebookModule } from 'ngx-facebook';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, RouterStateSerializer } from '@ngrx/router-store';
import { reducers, effects, CustomSerializer } from './store';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { TranslocoRootModule } from './transloco-root.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    HttpClientModule,
    FacebookModule.forRoot(),
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot(effects),
    StoreDevtoolsModule.instrument({
      maxAge: 25
    }),
    BrowserAnimationsModule,
    StoreRouterConnectingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    TranslocoRootModule
  ],
  exports: [

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PandaApiInterceptor,
      multi: true,
    },
    // {
    //   provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true
    // },
    // {
    //   provide: HTTP_INTERCEPTORS, useClass: PandaApiInterceptor, multi: true
    // },
    {
      provide: ErrorHandler, useClass: ErrorsHandler
    },
    {
      provide: RouterStateSerializer, useClass: CustomSerializer
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
