import * as fromPanda from '../actions/panda.actions';
import { PandaUser } from '../../core/models/user-panda';
import * as moment from 'moment';

import { environment } from '../../../environments/environment';

export interface PandaUserState {
  data: PandaUser;
  authenticating: boolean;
  authenticated: boolean;
  authenticateError: string;
  loading: boolean;
  loaded: boolean;
  redirect_url: string;
  saving_account: boolean;
  saving_company: boolean;
}

export const initialState: PandaUserState = {
  data: new PandaUser,
  authenticating: false,
  authenticated: false,
  authenticateError: null,
  loading: false,
  loaded: false,
  redirect_url: '/admin',
  saving_account: false,
  saving_company: false
};

export function pandaUserReducer(
  state = initialState,
  action: fromPanda.PandaUserActions
): PandaUserState {
  let localPandaUser = localStorage.getItem(environment.api.pandaUserStorageName);

  switch(action.type) {
    case fromPanda.PandaUserActionTypes.Panda_Log_In_Start: {
      return {
        ...state,
        authenticating: true,
        authenticated: false,
        authenticateError: null
      };
    }

    case fromPanda.PandaUserActionTypes.Panda_Log_In_Success: {
      return {
        ...state,
        authenticating: false,
        authenticated: true,
        authenticateError: null
      };
    }

    case fromPanda.PandaUserActionTypes.Panda_Log_In_Failed: {
      let errorMessage = action.payload.error.error.message[0];

      if(errorMessage.indexOf("invalid_credentials -") >= 0) {
        errorMessage = errorMessage.substring(22, errorMessage.length);
      }

      return {
        ...state,
        authenticating: false,
        authenticated: false,
        authenticateError: errorMessage
      };
    }

    case fromPanda.PandaUserActionTypes.Panda_Log_Out_Start: {
      return {
        ...state
      }
    }

    case fromPanda.PandaUserActionTypes.Panda_Log_Out_Success: {
      return {
        ...state,
        data: null,
        authenticated: false,
        loaded: false
      }
    }

    case fromPanda.PandaUserActionTypes.Panda_Log_Out_Fail: {
      return {
        ...state,
        data: null,
        authenticated: false,
        loaded: false
      }
    }

    case fromPanda.PandaUserActionTypes.Panda_Load_User_Start: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case fromPanda.PandaUserActionTypes.Panda_Load_User_Success: {
      if(localPandaUser === null || localPandaUser !== action.payload.data) {
        localStorage.setItem(environment.api.pandaUserStorageName, JSON.stringify(action.payload.data));
      }

      return {
        ...state,
        data: action.payload.data,
        loading: false,
        loaded: true
      };
    }

    case fromPanda.PandaUserActionTypes.Panda_Load_User_Fail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case fromPanda.PandaUserActionTypes.Panda_Validate_Auth_Token_Start: {
      return {
        ...state
      };
    }

    case fromPanda.PandaUserActionTypes.Panda_Validate_Auth_Token_Success: {
      const userObj = JSON.parse(localPandaUser);

      return {
        ...state,
        data: userObj,
        loaded: true,
        authenticated: true
      };
    }

    case fromPanda.PandaUserActionTypes.Panda_Validate_Auth_Token_Fail: {
      return {
        ...state
      };
    }

    case fromPanda.PandaUserActionTypes.Panda_Redirect_After_Auth: {
      return {
        ...state,
        redirect_url: action.payload
      };
    }

  }

  return state;
}

export const getPandaUser = (state: PandaUserState) => state.data;
export const getPandaUserAuthenticating = (state: PandaUserState) => state.authenticating;
export const getPandaUserAuthenticated = (state: PandaUserState) => state.authenticated;
export const getPandaUserLoading = (state: PandaUserState) => state.loading;
export const getPandaUserLoaded = (state: PandaUserState) => state.loaded;
export const getPandaRedirectUrl = (state: PandaUserState) => state.redirect_url;
