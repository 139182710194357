import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs/observable/of';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import * as fromStore from '../../store';
import * as fromUsers from '../actions/user.actions';
import { AuthService } from '../../core/services/users/auth/auth.service';
import { UsersService } from '../../core/services/users/users.service';

@Injectable()
export class UserEffects {
  constructor(
    private router: Router,
    private actions$: Actions,
    private authService: AuthService,
    private userService: UsersService,
    private store: Store<fromStore.GlobalAppState>
  ) {
  }

  @Effect({dispatch: true})
  initApp = this.actions$.pipe(
    ofType<fromUsers.UserActions>(fromUsers.UserActionTypes.Init_App),
    switchMap((action) => [
      (Object.keys(action.payload.data).length !== 0) ? new fromUsers.UserExists() : new fromUsers.ValidateAuthTokenStart({redirect: false})
    ])
  )

  @Effect()
  logInStart = this.actions$.pipe(
    ofType(fromUsers.UserActionTypes.Log_In_Start),
    switchMap((action) => {
      return this.authService.submitAuth(action).pipe(
        map(payload => new fromUsers.LogInSuccess(payload)),
        catchError(error => of(new fromUsers.LogInFailed(error)))
      )
    })
  )

  @Effect()
  logInSuccess = this.actions$.pipe(
    ofType(fromUsers.UserActionTypes.Log_In_Success),
    map(() => new fromUsers.LoadUserStart())
  )

  @Effect({dispatch: true})
  logOutStart = this.actions$.pipe(
    ofType(fromUsers.UserActionTypes.Log_Out_Start),
    switchMap((action) => {
      return this.authService.logUserOut(action).pipe(
        map(payload => new fromUsers.LogOutSuccess(payload)),
        catchError(error => of(new fromUsers.LogOutFail(error)))
      )
    })
  )

  @Effect({ dispatch: false })
  logOutSuccess = this.actions$.pipe(
    ofType(fromUsers.UserActionTypes.Log_Out_Success),
    switchMap(() => {
      return this.authService.removeLocalItems().pipe(
        tap( (action) => {
          this.router.navigate(['/dashboard/login'], { queryParams: { returnUrl: this.router.url } });
        })
      )
    })
  )

  @Effect()
  loadUserStart = this.actions$.pipe(
    ofType(fromUsers.UserActionTypes.Load_User_Start),
    switchMap(() => {
      return this.userService.getUserByToken({
        "_embed": "unread_alerts"
      }).pipe(
        map(response => new fromUsers.LoadUserSuccess(response))
      )
    })
  )

  @Effect({ dispatch: false })
  redirectAfterAuth = this.actions$.pipe(
    ofType<fromUsers.UserActions>(fromUsers.UserActionTypes.Redirect_After_Auth),
    tap( () => { this.router.navigate(['/dashboard']) })
  )

  @Effect({dispatch: true})
  validateAuthStart = this.actions$.pipe(
    ofType<fromUsers.UserActions>(fromUsers.UserActionTypes.Validate_Auth_Token_Start),
    switchMap((action) => {
      return this.authService.isAuthTokenValid().pipe(
        map( response => (response) ? new fromUsers.ValidateAuthTokenSuccess(action.payload) : new fromUsers.ValidateAuthTokenFail())
      )
    })
  )

  @Effect({ dispatch: false })
  validateAuthTokenSuccess = this.actions$.pipe(
    ofType<fromUsers.UserActions>(fromUsers.UserActionTypes.Validate_Auth_Token_Success),
    tap( (action) => {
      if(action.payload.redirect) {
        this.router.navigate([(action.payload.redirectUrl) ? action.payload.redirectUrl : '/dashboard'])
      }
    })
  )

  @Effect({ dispatch: false })
  validateAuthTokenFail = this.actions$.pipe(
    ofType<fromUsers.UserActions>(fromUsers.UserActionTypes.Validate_Auth_Token_Fail),
    tap( (action) => {
    })
  )

  @Effect({ dispatch: true })
  patchUserStart = this.actions$.pipe(
    ofType<fromUsers.UserActions>(fromUsers.UserActionTypes.Patch_User_Start),
    tap((action) => {
    }),
    switchMap((action) => {
      return this.userService.patchUserByToken(action.payload).pipe(
        map( response => (response) ? new fromUsers.PatchUserSuccess(response) : new fromUsers.PatchUserFail())
      )
    })
  )

  @Effect({ dispatch: true })
  forgotPassStart = this.actions$.pipe(
    ofType<fromUsers.UserActions>(fromUsers.UserActionTypes.Forgot_Pass_Start),
    switchMap((action) => {
      return this.authService.forgotPass(action.payload).pipe(
        map( response => new fromUsers.ForgotPassSuccess(response)),
        catchError(error => of(new fromUsers.ForgotPassFail(error)))
      )}
    )
  )

  @Effect({ dispatch: true })
  resetPassStart = this.actions$.pipe(
    ofType<fromUsers.UserActions>(fromUsers.UserActionTypes.Reset_Pass_Start),
    switchMap((action) => {
      return this.authService.resetPass(action.payload).pipe(
        map( response => new fromUsers.ResetPassSuccess(response)),
        catchError(error => of(new fromUsers.ResetPassFail(error)))
      )}
    )
  )

  @Effect({ dispatch: true })
  registerStart = this.actions$.pipe(
    ofType<fromUsers.UserActions>(fromUsers.UserActionTypes.Register_Start),
    switchMap((action) => {
      return this.authService.registerNewUser(action.payload).pipe(
        map( response => new fromUsers.RegisterSuccess(response)),
        catchError(error => of(new fromUsers.RegisterFail(error)))
      )}
    )
  )
}
