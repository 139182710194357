import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {
  @Input() maxNumberOfFiles: number = 1;
  @Output() uploadedFile: EventEmitter<[]> = new EventEmitter();
  files: any = [];

  constructor() { }

  ngOnInit() {
  }

  uploadFile(event) {
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      var reader = new FileReader();
      reader.readAsDataURL(element);
      reader.onload = (_event) => {
        element.image_url = reader.result;
      }

      this.files.push(element)
    }

    this.uploadedFile.emit(this.files);
  }
  deleteAttachment(index) {
    this.files.splice(index, 1)
    this.uploadedFile.emit(this.files);
  }
}
