import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as moment from 'moment';

import { environment } from '../../../../environments/environment';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  usersUrl = environment.api.baseUrl + '/owl/users';
  date = moment();

  constructor(
    private http: HttpClient
  ) {}

  getUserByToken(urlParams?: any): Observable<HttpEvent<any>> {
    return this.http.get<any>(this.usersUrl, { params: urlParams })
    .pipe(map(response => {
        return response;
    }));
  }

  patchUserByToken(userData): Observable<HttpEvent<any>> {
    return this.http.patch<any>(this.usersUrl, userData)
    .pipe(map(response => {
      return response;
    }));
  }
}
