import { Action } from '@ngrx/store';

export enum UserActionTypes {
  Init_App = '[User] Initialized App',
  User_Exists = '[User] User Already Exists',
  Log_In_Start = '[User] Starting Log In',
  Log_In_Success = '[User] Logged In',
  Log_In_Failed = '[User] Login Failed',
  Log_Out_Start = '[User] Start Log Out',
  Log_Out_Success = '[User] Log Out Success',
  Log_Out_Fail = '[User] Log Out Fail',
  Load_User_Start = '[User] Start Loading User',
  Load_User_Success = '[User] Got User Data',
  Load_User_Fail = '[User] Failed to Get User',
  Validate_Auth_Token_Start = '[User] Start Validating Auth Token',
  Validate_Auth_Token_Success = '[User] Successfully Validated Auth Token',
  Validate_Auth_Token_Fail = '[User] Failed to Validate Auth Token',
  Redirect_After_Auth = '[User] Redirect After Authentication',
  Patch_User_Start = '[User] Start to Patch User',
  Patch_User_Success = '[User] Successfully Patched User',
  Patch_User_Fail = '[User] Failed to Patch User',
  Forgot_Pass_Start = '[User] Start to Request Password Reset',
  Forgot_Pass_Success = '[User] Successfully Requested Password Reset',
  Forgot_Pass_Fail = '[User] Failed to Request Password Reset',
  Reset_Pass_Start = '[User] Start to Reset Password',
  Reset_Pass_Success = '[User] Successfully Reset Password',
  Reset_Pass_Fail = '[User] Failed to Reset Password',
  Register_Start = '[User] Start to Register User',
  Register_Success = '[User] Successfully Register User',
  Register_Fail = '[User] Failed to Register User'
}

export class InitApp implements Action {
  readonly type = UserActionTypes.Init_App;
  constructor(public payload: any = null) {}
}

export class UserExists implements Action {
  readonly type = UserActionTypes.User_Exists;
  constructor(public payload: any = null) {}
}

export class LogInStart implements Action {
  readonly type = UserActionTypes.Log_In_Start;
  constructor(public payload: any = null) {}
}

export class LogInSuccess implements Action {
  readonly type = UserActionTypes.Log_In_Success;
  constructor(public payload: any = null) {}
}

export class LogInFailed implements Action {
  readonly type = UserActionTypes.Log_In_Failed;
  constructor(public payload: any = null) {}
}

export class LogOutStart implements Action {
  readonly type = UserActionTypes.Log_Out_Start;
  constructor(public payload: any = null) {}
}

export class LogOutSuccess implements Action {
  readonly type = UserActionTypes.Log_Out_Success;
  constructor(public payload: any = null) {}
}

export class LogOutFail implements Action {
  readonly type = UserActionTypes.Log_Out_Fail;
  constructor(public payload: any = null) {}
}

export class LoadUserStart implements Action {
  readonly type = UserActionTypes.Load_User_Start;
  constructor(public payload: any = null) {}
}

export class LoadUserSuccess implements Action {
  readonly type = UserActionTypes.Load_User_Success;
  constructor(public payload: any = null) {}
}

export class LoadUserFail implements Action {
  readonly type = UserActionTypes.Load_User_Fail;
  constructor(public payload: any = null) {}
}

export class RedirectAfterAuth implements Action {
  readonly type = UserActionTypes.Redirect_After_Auth;
  constructor(public payload: any = '/dashboard') {}
}

export class ValidateAuthTokenStart implements Action {
  readonly type = UserActionTypes.Validate_Auth_Token_Start;
  constructor(public payload: any = null) {}
}

export class ValidateAuthTokenSuccess implements Action {
  readonly type = UserActionTypes.Validate_Auth_Token_Success;
  constructor(public payload: any = null) {}
}

export class ValidateAuthTokenFail implements Action {
  readonly type = UserActionTypes.Validate_Auth_Token_Fail;
  constructor(public payload: any = null) {}
}

export class PatchUserStart implements Action {
  readonly type = UserActionTypes.Patch_User_Start;
  constructor(public payload: any = null) {}
}

export class PatchUserSuccess implements Action {
  readonly type = UserActionTypes.Patch_User_Success;
  constructor(public payload: any = null) {}
}

export class PatchUserFail implements Action {
  readonly type = UserActionTypes.Patch_User_Fail;
  constructor(public payload: any = null) {}
}

export class ForgotPassStart implements Action {
  readonly type = UserActionTypes.Forgot_Pass_Start;
  constructor(public payload: any = null) {}
}

export class ForgotPassSuccess implements Action {
  readonly type = UserActionTypes.Forgot_Pass_Success;
  constructor(public payload: any = null) {}
}

export class ForgotPassFail implements Action {
  readonly type = UserActionTypes.Forgot_Pass_Fail;
  constructor(public payload: any = null) {}
}

export class ResetPassStart implements Action {
  readonly type = UserActionTypes.Reset_Pass_Start;
  constructor(public payload: any = null) {}
}

export class ResetPassSuccess implements Action {
  readonly type = UserActionTypes.Reset_Pass_Success;
  constructor(public payload: any = null) {}
}

export class ResetPassFail implements Action {
  readonly type = UserActionTypes.Reset_Pass_Fail;
  constructor(public payload: any = null) {}
}

export class RegisterStart implements Action {
  readonly type = UserActionTypes.Register_Start;
  constructor(public payload: any = null) {}
}

export class RegisterSuccess implements Action {
  readonly type = UserActionTypes.Register_Success;
  constructor(public payload: any = null) {}
}

export class RegisterFail implements Action {
  readonly type = UserActionTypes.Register_Fail;
  constructor(public payload: any = null) {}
}

export type UserActions =
  | InitApp
  | LogInStart
  | LogInSuccess
  | LogInFailed
  | LogOutStart
  | LogOutSuccess
  | LogOutFail
  | LoadUserStart
  | LoadUserSuccess
  | LoadUserFail
  | RedirectAfterAuth
  | ValidateAuthTokenStart
  | ValidateAuthTokenSuccess
  | ValidateAuthTokenFail
  | PatchUserStart
  | PatchUserSuccess
  | PatchUserFail
  | ForgotPassStart
  | ForgotPassSuccess
  | ForgotPassFail
  | ResetPassStart
  | ResetPassSuccess
  | ResetPassFail
  | RegisterStart
  | RegisterSuccess
  | RegisterFail;
