import { ActivatedRouteSnapshot, RouterStateSnapshot, Params } from '@angular/router';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromRouter from '@ngrx/router-store';
import * as fromUser from '../reducers/user.reducer';
import * as fromPanda from '../reducers/panda.reducer';

export interface RouterStateUrl {
  url: string;
  queryParams: Params;
  params: Params;
}

export interface GlobalAppState {
  routerReducer: fromRouter.RouterReducerState<RouterStateUrl>,
  user: fromUser.UserState,
  panda_user: fromPanda.PandaUserState
}

export const reducers: ActionReducerMap<GlobalAppState> = {
  routerReducer: fromRouter.routerReducer,
  user: fromUser.userReducer,
  panda_user: fromPanda.pandaUserReducer
}

export const getRouterState = createFeatureSelector<
  fromRouter.RouterReducerState<RouterStateUrl>
>('routerReducer');

export const getUserState = createFeatureSelector<
  fromUser.UserState
>('user');

export const getPandaUserState = createFeatureSelector<
  fromUser.UserState
>('panda_user');

export class CustomSerializer implements fromRouter.RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const { url } = routerState;
    const { queryParams } = routerState.root;

    let state: ActivatedRouteSnapshot = routerState.root;
    while(state.firstChild) {
      state = state.firstChild;
    }
    const { params } = state;

    return { url, queryParams, params };
  }
}
