import { createSelector  } from '@ngrx/store';

import * as fromRoot from '../reducers';
import * as fromUsers from '../reducers/user.reducer';

export const getRootUserState = createSelector((state: fromRoot.GlobalAppState) => state.user);
export const getCurrentUser = createSelector(getRootUserState, fromUsers.getUser);
export const getUserLoaded = createSelector(getRootUserState, fromUsers.getUserLoaded);
export const getUserLoading = createSelector(getRootUserState, fromUsers.getUserLoading);
export const getUserAuthenticated = createSelector(getRootUserState, fromUsers.getUserAuthenticated);
export const getUserAuthenticating = createSelector(getRootUserState, fromUsers.getUserAuthenticating);
export const getRedirectUrl = createSelector(getRootUserState, fromUsers.getRedirectUrl);
