import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/do';
import { environment } from '../../../environments/environment';
import { AuthService } from '../services/users/auth/auth.service';

import { Store } from '@ngrx/store';
import * as fromStore from '../../store';
import { User } from '../models/user';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<{ user: User }>,
    private authService: AuthService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const bearerToken = this.authService.getAuthToken();

    if(request.url.indexOf('/apply') >= 0) {
      /*
      ** Handle Uploading Resume
      */
      const headers = new HttpHeaders({
        'API-KEY': environment.api.apiKey
      });

      const cloneReq = request.clone({headers: headers});

      return next.handle(cloneReq).do((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // do stuff with response
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            if(bearerToken && err.url.indexOf('/logout') < 0) {
              this.store.dispatch(new fromStore.LogOutStart(bearerToken));
            } else if((bearerToken && err.url.indexOf('/logout') >= 0) || !bearerToken) {
              this.store.dispatch(new fromStore.LogOutSuccess(bearerToken));
            }
          }
        }
      });
    } else if(request.url.indexOf('/lockscreen/logo') >= 0) {
      /*
      ** Handle Custom Logo
      */
      const headers = new HttpHeaders({
        'API-KEY': environment.api.apiKey,
        'Authorization': 'Bearer ' + bearerToken
      });

      const cloneReq = request.clone({headers: headers});

      return next.handle(cloneReq).do((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // do stuff with response
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            if(bearerToken && err.url.indexOf('/logout') < 0) {
              this.store.dispatch(new fromStore.LogOutStart(bearerToken));
            } else if((bearerToken && err.url.indexOf('/logout') >= 0) || !bearerToken) {
              this.store.dispatch(new fromStore.LogOutSuccess(bearerToken));
            }
          }
        }
      });
    } else if(request.url.indexOf('/distributors/policies') >= 0 && request.method == "POST") {
      /*
      ** Handle Distributor Policy Document
      */
      const headers = new HttpHeaders({
        'API-KEY': environment.api.apiKey,
        'Authorization': 'Bearer ' + bearerToken
      });

      const cloneReq = request.clone({headers: headers});

      return next.handle(cloneReq).do((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // do stuff with response
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            if(bearerToken && err.url.indexOf('/logout') < 0) {
              this.store.dispatch(new fromStore.LogOutStart(bearerToken));
            } else if((bearerToken && err.url.indexOf('/logout') >= 0) || !bearerToken) {
              this.store.dispatch(new fromStore.LogOutSuccess(bearerToken));
            }
          }
        }
      });
    } else if(request.url.indexOf('.pdf') >= 0 && request.method == "GET") {
      /*
      ** Handle Distributor Policy Document
      */
      const headers = new HttpHeaders({
        'API-KEY': environment.api.apiKey,
        'Authorization': 'Bearer ' + bearerToken
      });

      console.log("I AM GETTING A PDF PREVIEW");

      const cloneReq = request.clone({headers: headers, responseType: 'blob'});

      return next.handle(cloneReq).do((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // do stuff with response
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            if(bearerToken && err.url.indexOf('/logout') < 0) {
              this.store.dispatch(new fromStore.LogOutStart(bearerToken));
            } else if((bearerToken && err.url.indexOf('/logout') >= 0) || !bearerToken) {
              this.store.dispatch(new fromStore.LogOutSuccess(bearerToken));
            }
          }
        }
      });
    } else if(
      request.url.indexOf("/owl") >= 0 ||
      request.url.indexOf("/drivecare/") >= 0 &&
      request.url.indexOf("/apply") < 0
    ) {
      /*
      ** Handle All Other Requests
      */
      const headers = new HttpHeaders({
        'API-KEY': environment.api.apiKey,
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + bearerToken
      });

      const cloneReq = request.clone({headers: headers});

      return next.handle(cloneReq).do((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // do stuff with response
          // console.log("API Response:", event);
        }
      }, (err: any) => {
        console.log("API Error:", err);

        if (err instanceof HttpErrorResponse) {
          if (err.status === 401 && this.activatedRoute.snapshot['_routerState'].url.indexOf('/admin') < 0) {
            if(bearerToken && err.url.indexOf('/logout') < 0) {
              this.store.dispatch(new fromStore.LogOutStart(bearerToken));
            } else if((bearerToken && err.url.indexOf('/logout') >= 0) || !bearerToken) {
              this.store.dispatch(new fromStore.LogOutSuccess(bearerToken));
            }
          }
        }
      });
    }

    // send cloned request with header to the next handler.
    return next.handle(request);
  }
}
