import { Component, isDevMode } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import { MixpanelService } from './core/services/mixpanel/mixpanel.service';

import * as fromStore from './store';
import * as userActions from './store/actions/user.actions';
import * as pandaActions from './store/actions/panda.actions';

import * as environment from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'drivecare';
  loadingUser: boolean = true;
  dispatchedUser: boolean = false;
  dispatchedPandaUser: boolean = false;
  userAuthenticated$: any;
  userAuthenticated: boolean = false;
  userState: any;
  pandaUserAuthenticated$: any;
  pandaUserAuthenticated: boolean = false;
  pandaUserState: any;
  mixpanelTracked: boolean = false;

  constructor(
    private router: Router,
    private mixpanelService: MixpanelService,
    private store: Store<fromStore.GlobalAppState>
  ) {
    // Load User State
    this.userState = this.store.select(fromStore.getUserState).subscribe( (userState) => {
      if(!this.dispatchedUser) {
        this.dispatchedUser = true;
        this.store.dispatch(new userActions.InitApp(userState));
      }
    });

    // Load User State
    this.pandaUserState = this.store.select(fromStore.getPandaUserState).subscribe( (pandaUserState) => {
      if(!this.dispatchedPandaUser) {
        this.dispatchedPandaUser = true;
        this.store.dispatch(new pandaActions.InitPandaApp(pandaUserState));
      }
    });
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          this.mixpanelService.init("559ea7d29ce4c28dc3317c51ac59edcc");

          return;
      }

      if(!isDevMode()) {
        window.scrollTo(0, 0)
      }
    });
  }
}
